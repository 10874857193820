exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._34fQP3Wmr7NrbDu3RPyeIO{font-size:larger}._3tQYfGdsjvXjEpxQoG4Q-S{font-size:smaller}._2WT6_dhGnY3TFDBS06Jbmy{padding-top:5px;display:flex;flex-direction:column;align-items:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"ZoneLabel": "_34fQP3Wmr7NrbDu3RPyeIO",
	"WrongZoneLabel": "_3tQYfGdsjvXjEpxQoG4Q-S",
	"ZoneContainer": "_2WT6_dhGnY3TFDBS06Jbmy"
};