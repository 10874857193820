import * as React from 'react';
import * as style from './style.css';
import { LearnMoreModal } from '../LearnMoreModal';
import { UnitedWayBanner } from '../UnitedWayBanner';
import { IParkingStore } from '../../stores/IParkingStore';
import { TYPES } from '../../constants';
import { lazyInject } from '../../utils/inversify.config';
import { observer } from 'mobx-react';

@observer
export class MainHeader extends React.Component<{}> {

  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  
  public render() {
    
    return (
      <div className={style.Header}>
        <div className={style.InfoBox}>
          <div className={style.Subtitle}>
            Park here
            <br />
            { this.parkingStore.zoneInfo!=null && (
             <a  href={this.parkingStore.zoneInfo.sponsorWebsite}>Sponsored by {this.parkingStore.zoneInfo.sponsor}</a> 
             ) 
            }
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              All net proceeds go to <UnitedWayBanner />
            </div>
            { this.parkingStore.clientInfo!=null && (
              <div>
                <span className={style.Money}  > { (this.formatDollars(this.parkingStore.clientInfo.fundsRaisedDollars)) }</span><span> raised and counting! </span> 
            </div> )
            }
          </div>
          <div className={style.HeaderButton}>
            <LearnMoreModal />
          </div>
        </div>
      </div>
    );
  }

  public formatDollars(dollars:Number):String {
    var dollarsAsString:string = dollars.toString();
    var formatted:string = "";
    for(let i=0; i < dollarsAsString.length; i++) {
      if(i >0  && i % 3 ==0)
        formatted = "," +formatted;
      formatted = dollarsAsString.charAt(dollarsAsString.length-i-1) + formatted;
    }

    return "$" + formatted;
  }
 }
