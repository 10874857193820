exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EtDY6zAl7XlDHoArrGE2d{padding-top:10px;padding-right:2px;display:flex;flex-direction:column;align-items:center;justify-content:center}::-webkit-input-placeholder{text-align:left}input:-moz-placeholder{text-align:left}", ""]);

// exports
exports.locals = {
	"ZoneDetailsContainer": "EtDY6zAl7XlDHoArrGE2d",
	"ZoneInput": "_1065oFh_cn7as4T93BIIiL"
};