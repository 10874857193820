exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._36VGA3Q-MDUImY_52Xbhk-{padding:10px;max-width:500px;margin:auto}._2pGCgTL-wm2EHAxhzQK3mb{width:100%;text-align:center;color:#3b4a6a;border-bottom:1px solid #e6e5f2;line-height:.1em;margin:10px 0 5px}._13LJOv_G9_XuiGFiI-GXkD{display:none}._1g6GJRRezEMpWEwtRQSUri{background:#fff;padding:0}", ""]);

// exports
exports.locals = {
	"PaymentRequest": "_36VGA3Q-MDUImY_52Xbhk-",
	"PaymentTypesSeparator": "_2pGCgTL-wm2EHAxhzQK3mb",
	"PaymentRequestContainer": "_13LJOv_G9_XuiGFiI-GXkD",
	"SeparatorBorder": "_1g6GJRRezEMpWEwtRQSUri"
};