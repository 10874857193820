import * as React from 'react';
import * as style from './style.css';
import { IParkingStore } from '../../stores/IParkingStore';
import { TYPES } from '../../constants';
import { lazyInject } from '../../utils/inversify.config';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface IZoneInfoDisplayState {
 
}

@observer
export class ZoneInfoDisplay extends React.Component<{}, IZoneInfoDisplayState> {
  
  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  

  constructor(props: {}, context: any) {
    super(props, context);
    this.state = {
     
    };
  }

  public render() {
    return (
     
        <span className={style.ZoneContainer}>
            <span className={style.ZoneLabel}>
              <b >Zone {this.parkingStore.zoneInfo.zoneId}</b>
            </span>
            <span>{this.parkingStore.zoneInfo.locationStreetAddress}
            <br/> {this.parkingStore.zoneInfo.locationCity}, {this.parkingStore.zoneInfo.locationState} {this.parkingStore.zoneInfo.locationZip5}
            </span>
            <span className={style.WrongZoneLabel}>
              <Link to="/EnterZone" onClick={this.wrongZoneClicked.bind(this)}>(Wrong Zone?)</Link>
            </span>
         </span>

    );
  }

  private wrongZoneClicked():void {
    this.parkingStore.setZoneInfo(null);
  }
 
}
