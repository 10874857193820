import * as React from 'react';
import { Tab } from 'semantic-ui-react';
import * as style from './style.css';
import { Table } from 'semantic-ui-react'



export interface IFindParkingProps {
}
export interface IFindParkingState {
    width: Number;
    height: Number;

}

export class FindParking extends React.Component<IFindParkingProps, IFindParkingState> {

    constructor(props: IFindParkingProps, context: any) {
        super(props, context);
        this.state = { width: 0, height: 0 }

    }

    mapPane = {
        menuItem: 'Map',
        render: () => <Tab.Pane> <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1e_fMuxFiN37_xNJO6JM7YJxdYN-h_Qxx" width="{this.state.width}" height="500"></iframe></Tab.Pane>
    };

    listPane = {
        menuItem: 'List',
        render: () =>
            <Tab.Pane>
                <Table >
                    <Table.Row>
                        <Table.Cell>
                            Zone 1
                        </Table.Cell>
                        <Table.Cell>
                            4 spaces
                            <br/>1200 Park Street, Columbia SC 29201 <a href="https://www.google.com/maps/place/1200+Park+St,+Columbia,+SC+29201/@34.000644,-81.03908,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8bb2b87f2c6c1:0x98228b9839b23ada!8m2!3d34.000644!4d-81.0368913" >Map</a> 
                        </Table.Cell> 
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            Zone 5
                        </Table.Cell>
                        <Table.Cell>
                            11 Spaces
                            <br/>1013 Gervais St, Columbia SC 29201 <a href="https://www.google.com/maps/place/1013+Gervais+St,+Columbia,+SC+29201/@34.000779,-81.0385877,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8bb2b840a85dd:0x5e96eb4358ec0181!8m2!3d34.000779!4d-81.036399" >Map</a> 
                            <br/>These spaces are up againt the Mellow Mushroom building.
                        </Table.Cell>
                    </Table.Row>
                </Table>
            </Tab.Pane>
    }
    panes = [this.mapPane, this.listPane];
    public render() {
        return (
            <div className={style.MapContainer}>
                <Tab panes={this.panes} />

            </div>
        );
    }

    public componentDidMount() {
        this.updateWindowDimensions();
    }
    private updateWindowDimensions(): void {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

}