exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._33a2rWFaB0srBin21KdC9V{font-family:Doppio One,sans-serif!important;color:#fff!important;float:left;margin-top:0!important;padding-top:1px!important;padding-left:2px!important;margin-bottom:0!important}._1rNO1kDKt90oj-7b-_kfYu{background:#08f}._3yYexfKwS3BC-ybunyGzDX{background:transparent!important;padding-top:4px;margin-left:auto}._1L4p15QX8Nhim-uXOuUv8m{background:transparent!important;color:#fff!important;padding:0!important}._11MT71R_IsxPbhpbzJ116z{color:#fff;padding-bottom:20px}._1vQFEZ8yoynPPFEt57uqhY{text-align:center;padding-top:10px}.CogzMhg5M67P70RxZX1-p{font-size:1.3em;text-align:center;line-height:1.6}body{font-size:16px;cursor:pointer}.C5Lfiz8skadDY78eDHqG-{padding:10px;display:flex;flex-direction:row;align-content:center}._3jllzdUODvS0BQSZviq8zf{display:\"none\";position:absolute;top:40px;right:10px;z-index:999}._3aSOv37i6ZV1VrStu3P7L9{color:#fff!important;float:left;margin-top:0!important;padding-top:1px!important;padding-left:2px!important;margin-right:0!important}._3HeqKi-SOWOwRZU5Aob8xt{width:10rem!important}@supports (padding:max(0px)){.C5Lfiz8skadDY78eDHqG-{padding-left:max(12px,env(safe-area-inset-left));padding-right:max(12px,env(safe-area-inset-right))}._3jllzdUODvS0BQSZviq8zf{right:max(10px,env(safe-area-inset-left))}}", ""]);

// exports
exports.locals = {
	"HeaderText": "_33a2rWFaB0srBin21KdC9V",
	"Header": "_1rNO1kDKt90oj-7b-_kfYu",
	"MenuContainer": "_3yYexfKwS3BC-ybunyGzDX",
	"MenuIcon": "_1L4p15QX8Nhim-uXOuUv8m",
	"InfoBox": "_11MT71R_IsxPbhpbzJ116z",
	"HeaderButton": "_1vQFEZ8yoynPPFEt57uqhY",
	"Subtitle": "CogzMhg5M67P70RxZX1-p",
	"Logo": "C5Lfiz8skadDY78eDHqG-",
	"Menu": "_3jllzdUODvS0BQSZviq8zf",
	"BarsIcon": "_3aSOv37i6ZV1VrStu3P7L9",
	"MenuUI": "_3HeqKi-SOWOwRZU5Aob8xt"
};