import * as React from 'react';
import * as style from './style.css';
import { lazyInject } from '../../utils/inversify.config';
import { TYPES } from '../../constants';
import { IParkingStore } from '../../stores/IParkingStore';
import { autorun } from 'mobx';
import { PaymentResponse, PaymentResponseResultEnum } from '../../remoteapi/generated/api';
import { IAPIService2 } from '../../services/IAPIService2';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IAnalyticsService} from '../../services/IAnalyticsService';
import { GA_EVENTS } from '../../constants/gaEvents';


export interface IPaymentRequestButtonProps extends RouteComponentProps<any> {}

class PaymentRequestButton extends React.Component<IPaymentRequestButtonProps> {
  @lazyInject(TYPES.AnalyticService) private analyticsService: IAnalyticsService;
  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  @lazyInject(TYPES.APIService) private apiService: IAPIService2;

  constructor(props: IPaymentRequestButtonProps, context: any) {
    super(props, context);
    this.state = {};

    const stripe = Stripe(this.parkingStore.clientInfo.stripeKey);

    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Park to Give',
        amount: 0
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    const elements = stripe.elements();
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest
    });

    (async () => {
      // Check the availability of the Payment Request API first.
      const result = await paymentRequest.canMakePayment();
      if (result) {
        prButton.mount('#payment-request-button');
        document.getElementById('payment-request-container').style.display =
          'block';
      } else {
        document.getElementById('payment-request-button').style.display =
          'none';
      }
    })();

    paymentRequest.on('token', async ev => {
      // Send the token to your server to charge it!
      const response = await this.apiService.sendPaymentInfo(
        ev.token.id,
        this.parkingStore.zoneInfo.zoneId,
        this.parkingStore.tagNumber,
        this.parkingStore.selectedPrice,
        ev.payerEmail
      );

      if (response.result === PaymentResponseResultEnum.SUCCESS) {
        // Report to the browser that the payment was successful, prompting
        // it to close the browser payment interface.
        ev.complete('success');
        this.analyticsService.successfulParkingTx(response.totalAmount,response.localId,response.parkingAmount);
        this.analyticsService.event(GA_EVENTS.PAYMENT_SUCCESS);
        this.props.history.push('/confirmation', { paymentResponse: response });
      } else {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      }
    });

    const priceUpdater = autorun(() => {
      let price = this.parkingStore.selectedPrice;
      if (price > 100) {
        price = null;
      }

      paymentRequest.update({
        currency: 'usd',
        total: {
          amount: price * 100,
          label: 'Park to Give'
        }
      });
    });
  }

  public render() {
    return (
      <div
        className={style.PaymentRequestContainer}
        id="payment-request-container"
      >
        <div className={style.PaymentRequest} id="payment-request-button" />
        <div className={style.PaymentTypesSeparator}>
          <span className={style.SeparatorBorder}>
            or enter your payment information below
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentRequestButton);
