exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1nvAVjF8jKsvhLGTOMPRfZ{padding:10px;max-width:500px;margin:auto;display:flex;flex-direction:column;align-items:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"ReservedMessageContainer": "_1nvAVjF8jKsvhLGTOMPRfZ"
};