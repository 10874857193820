exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._348m1XpRCc5VU2G1z52Cxh{padding-top:5px;display:flex;flex-direction:column;align-items:center;justify-content:center}._348m1XpRCc5VU2G1z52Cxh p{margin:.1em}._1Y1jTziCo26KAwKbIZU7U6{font-size:larger}::-webkit-input-placeholder{text-align:left}input:-moz-placeholder{text-align:left}._1JkJe-ddViym4xuhywu3X5{background-color:#08f!important}", ""]);

// exports
exports.locals = {
	"PriceSelectorContainer": "_348m1XpRCc5VU2G1z52Cxh",
	"ZoneInfo": "_1Y1jTziCo26KAwKbIZU7U6",
	"blue": "_1JkJe-ddViym4xuhywu3X5"
};