import * as React from 'react';
import * as style from './style.css';
import { Input, Label, SemanticCOLORS, Button } from 'semantic-ui-react';
import { Message, Icon } from 'semantic-ui-react';

import { MainHeader } from '../../components/MainHeader';

import { RouteComponentProps, Redirect } from 'react-router-dom';
import { IParkingStore, IPosition } from '../../stores/IParkingStore';
import { IAPIService2 } from '../../services/IAPIService2';
import { ZoneInfo } from '../../remoteapi/generated/api';
import { lazyInject } from '../../utils/inversify.config';
import { TYPES } from '../../constants';
import { IAnalyticsService } from '../../services/IAnalyticsService';
import { observer } from 'mobx-react';


interface IMatchParams {
  id: string;
}

export interface IZoneDetailsProps extends RouteComponentProps<IMatchParams> {
}
export interface IZoneDetailsState {
  zoneId:string;
  inputError: boolean;
  inputColor: SemanticCOLORS;
  showZoneNotFoundMsg: boolean;

}
@observer
export class ZoneDetails extends React.Component<IZoneDetailsProps, IZoneDetailsState> {
  @lazyInject(TYPES.AnalyticService)
  private analyticsService: IAnalyticsService;

  @lazyInject(TYPES.ParkingStore)
  private parkingStore: IParkingStore;

  @lazyInject(TYPES.APIService) private apiService: IAPIService2;


  constructor(props: IZoneDetailsProps, context: any) {
    super(props, context);
    this.state = {
      zoneId : "",
      inputError: false,
      inputColor: null,
      showZoneNotFoundMsg : false
    };
    this.handleZoneInput=this.handleZoneInput.bind(this);
    this.handleSubmitButton = this.handleSubmitButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public render() {
    if(this.parkingStore.zoneInfo!==null) {
      let url:string = "/p/" + this.parkingStore.zoneInfo.zoneId 
      return (<Redirect to={url}/>);
    }
    else return (
      
      <div>
        <MainHeader />
        <div className={style.ZoneDetailsContainer}>
        
        <div className={style.ZoneDetailsContainer}>
        <Input
          placeholder="Zone Number"
          labelPosition="left"
          type="text"
          className={style.ZoneInput}
          error={this.state.inputError}
          value={this.state.zoneId}
          onChange={this.handleZoneInput}
          onKeyDown= {this.handleKeyDown}
         
        >
          
          <Label
            style={
              this.state.inputColor === 'blue'
                ? { backgroundColor: '#0088FF', color: '#ffffff' }
                : this.state.inputColor === 'red'
                  ? { backgroundColor: '#c0392b', color: '#ffffff' }
                  : {}
            }
          >
          Zone Number
          </Label>
          <input
            min="1"
            style={{ textAlign: 'right' }}
          />
        </Input>
        </div>
       
        <div className={style.ZoneDetailsContainer}>
          <Button id="submitButton" onClick={this.handleSubmitButton}>Submit</Button>
        </div>
       
        { this.state.showZoneNotFoundMsg && (
        <div className={style.ZoneDetailsContainer}>
          <Message negative>
            <Message.Header>
              <Icon name="exclamation circle" /> Zone number not recognized. Please try again.
            </Message.Header>
          
          </Message>
        </div>
        )}
      </div>

    </div>
    );
  }

  public handleKeyDown = (e) => {       
  if(e.keyCode==13) {document.getElementById("submitButton").click();}
  }

  public handleSubmitButton = () => {
    this.apiService.loadZoneInfoNow(this.state.zoneId).then((zone) => {
      if(zone===null) {
        this.setState({...this.state,showZoneNotFoundMsg :true });
      }
      else {
        this.parkingStore.setSelectedPrice(zone.currentPrice);
        this.parkingStore.setZoneInfo(zone);
        
        this.setState({...this.state,showZoneNotFoundMsg :false });
      }
    });
    
    
  };

  public handleZoneInput(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({...this.state, zoneId: e.target.value});
  }

  public componentDidMount() {
    if(this.parkingStore.lastLocation===null ) {
      var p : IPosition;
      p = {
        latitude : null,
        longitude : null,
        timestamp : Date.now()
      };
      this.parkingStore.setLastLocation(p);
      navigator.geolocation.getCurrentPosition((pos => {
          var p : IPosition;
          p = {
            latitude : pos.coords.latitude,
            longitude : pos.coords.longitude,
            timestamp : Date.now()
          };
          this.parkingStore.setLastLocation(p);
          this.apiService.loadNearestZoneInfo(pos.coords.latitude,pos.coords.longitude);
        })
       
      );
    }
  }
}
