import { IParkingStore, IPosition } from './IParkingStore';
// Services
import { observable, action } from 'mobx';
import { injectable } from 'inversify';
import { ZoneInfo,ClientInfo } from '../remoteapi/generated';

@injectable()
export class ParkingStore implements IParkingStore {
  
  @observable public selectedPrice: number = 0;
  @observable public clientInfo: ClientInfo = null;
  @observable public tagNumber: string = "";
  @observable public zoneInfo : ZoneInfo = null;
  public lastLocation: IPosition=null;

  public parkingSpotId: string;
  
  @action
  public setSelectedPrice(selectedPrice: number) {
    this.selectedPrice = selectedPrice;
  }

  @action
  public setClientInfo(clientInfo: ClientInfo) {
    this.clientInfo = clientInfo;
  }
  @action
  public setTagNumber(tag: string): void {
    this.tagNumber = tag;
  }

  @action
  public setZoneInfo(zone : ZoneInfo) : void {
    this.zoneInfo = zone;
  }

  public setParkingSpotId(id: string) {
    this.parkingSpotId = id;
  }

  public setLastLocation(position: IPosition) {
    this.lastLocation = position;
  }
  
}
