import * as React from 'react';
// Services
import { BrowserRouter as Router, Route } from 'react-router-dom';
// Components
import { StripeProvider } from 'react-stripe-elements';
// Routes
import { Home } from './Home';
import { ZoneDetails } from './ZoneDetails';
import { PaymentConfirmation } from './PaymentConfirmation';
import { IAPIService2 } from '../services/IAPIService2';
import { IAnalyticsService } from '../services/IAnalyticsService';
import { lazyInject } from '../utils/inversify.config';
import { TYPES } from '../constants';
import { observer } from 'mobx-react';
import { IParkingStore } from '../stores/IParkingStore';
import { MenuBar } from '../components/MenuBar';
import { FindParking } from './FindParking';

@observer
export class App extends React.Component<{}, {}> {
  @lazyInject(TYPES.APIService) private apiService: IAPIService2;
  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  @lazyInject(TYPES.AnalyticService) private AnalyticService: IAnalyticsService;


  public componentWillMount() {
    
    this.AnalyticService.pageView(window.location.pathname)
    this.apiService.loadClientInfo();
  }

  public render() {
    if (this.parkingStore.clientInfo === null) {
      return <div />;
    } else {
      return (
        <StripeProvider apiKey={this.parkingStore.clientInfo.stripeKey}>
          <Router>
            <div>
              <MenuBar />
              <Route exact path ="/" component={ZoneDetails} />
              <Route exact path="/EnterZone" component={ZoneDetails} />
              <Route exact path="/FindParking" component={FindParking} />
              <Route exact path="/p/:id" component={Home} />
              <Route
                exact
                path="/confirmation"
                component={PaymentConfirmation}
              />
            </div>
          </Router>
        </StripeProvider>
      );
    }
  }
}
