import * as React from 'react';
import * as style from './style.css';
import { Message, Icon } from 'semantic-ui-react';

import { lazyInject } from '../../utils/inversify.config';

import { TYPES } from '../../constants';
import { IParkingStore } from '../../stores/IParkingStore';
import { Link } from 'react-router-dom';
import { ZoneInfoDisplay } from '../ZoneInfoDisplay'

export interface IReservedMessageState {
  displayMessage: boolean;
}

export class ReservedMessage extends React.Component<{},IReservedMessageState> {

  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  
  constructor(props: {}, context: any) {
    super(props, context);
    this.state = { displayMessage: true };
  }

  public render() {
    let reservedHours = this.parkingStore.zoneInfo.reservedScheduleDescr.map((reservedEntry) => {
      return (
        <span><br/>{reservedEntry}</span>
      );
    });

    return (
      <div
        className={style.ReservedMessageContainer}
      >
        <ZoneInfoDisplay/>
        <Message negative>
          <Message.Header>
            <Icon name="exclamation circle" /> We're sorry this zone is
            currently reserved
          </Message.Header>
          <p>
            Our spaces are provided by local businesses
             who reserve their parking for employees during the day and make their spaces
              available through Park to Give during evenings and weekends.<br />
              <br />This location is reserved during the following times: <br /> 
              {reservedHours}
              <br/>
              <br/>
            Any unauthorized vehicles parking during the above reserved times will be towed.  
             Please come back at a non-reserved time to use Park to Give. Thank you for
            your understanding!
          </p>
        </Message>
      </div>
    );
  }
  private wrongZoneClicked() {
    this.parkingStore.setZoneInfo(null);
    return;
  }
  
}
