import * as React from 'react';
import * as unitedway from '../../../../assets/images/unitedway_horizontal-main.png';

export class UnitedWayBanner extends React.Component<{}> {
  public render() {
    return (
      <span style={{ paddingLeft: '5px' }}>
        <img
          style={{ paddingTop: '5px' }}
          src={unitedway}
          width="130"
          height="31"
        />
      </span>
    );
  }
}
