import { asyncComponent } from 'react-async-component';

const TermsOfServiceAsync = asyncComponent({
  name: 'TermsOfServiceAsync',
  serverMode: 'resolve',
  resolve: () => {
    return import(/* webpackChunkName: "TOS" */ './index');
  }
});

export default TermsOfServiceAsync;
