import * as React from 'react';
import * as style from './style.css';
import { Message, Icon, Loader } from 'semantic-ui-react';
import { PaymentResponse } from '../../remoteapi/generated/api';
import { RouteComponentProps } from 'react-router-dom';
import { lazyInject } from '../../utils/inversify.config';
import { TYPES } from '../../constants';
import { IAPIService2 } from '../../services/IAPIService2';
import { UnitedWayListItem } from '../../components/UnitedWayListItem';
import { GA_EVENTS } from '../../constants/gaEvents';
import { IAnalyticsService } from '../../services/IAnalyticsService';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon } from 'react-share';

interface IStateDetails {
  paymentResponse: PaymentResponse;
}
export interface IPaymentConfirmationProps
  extends RouteComponentProps<IStateDetails> {}

export interface IPaymentConfirmationState {
  email: string;
  buttonProcessing: boolean;
  successful: boolean;
  receiptEmailed: boolean;
}

export class PaymentConfirmation extends React.Component<
  IPaymentConfirmationProps,
  IPaymentConfirmationState
> {
  @lazyInject(TYPES.AnalyticService)
  private analyticsService: IAnalyticsService;

  @lazyInject(TYPES.APIService)
  private apiService: IAPIService2;
  private paymentResponse: PaymentResponse;

  constructor(props: IPaymentConfirmationProps, context: any) {
    super(props, context);
    this.paymentResponse = this.props.location.state.paymentResponse;
    this.state = {
      email: this.paymentResponse.email,
      buttonProcessing: false,
      successful: false,
      receiptEmailed: this.paymentResponse.email != null
    };
  }

  public render() {
    return (
      <div>
        <div className={style.Container}>
          <Message style={{ background: '#ffffff' }}>
            <div style={{ textAlign: 'center' }}>
              <Message.Header>
                <Icon
                  name="checkmark"
                  style={{ color: '#0088FF' }}
                  size="huge"
                />
                <div className={style.TextHeader}>Payment Successful!</div>
              </Message.Header>
              <div className={style.HeaderSubtitle}>
              Thank you for supporting Park to Give and the United Way of the Midlands!
              </div>
              <br />
            </div>
            <div className={style.UnitedWayDiv}>
              <UnitedWayListItem />
              <div>${this.paymentResponse.charityAmount}</div>
            </div>
            Parking:
            <span className={style.Prices}>
              ${this.paymentResponse.parkingAmount}
          </span>
          <br />
          <hr />
          <b>
              Total:
              <span className={style.Prices}>
                ${this.paymentResponse.totalAmount}
              </span>
            </b>
            <br />
            
          </Message>
          <div
            hidden={!this.state.receiptEmailed}
            style={{ textAlign: 'center' }}
          >
            Receipt emailed to {this.state.email}
          </div>
          <form
            className={style.EmailForm}
            id="email-form"
            onSubmit={this.handleOnSubmit}
            hidden={this.state.receiptEmailed}
          >
            <section>
              <h2 className={style.EmailHeader}>
                Optional: Enter your email below for your receipt
              </h2>￼
              <fieldset className="with-state">
                <label>
                  <span>Email</span>
                  <input
                    name="email"
                    type="email"
                    className="field"
                    placeholder="jenny@example.com"
                    defaultValue={this.paymentResponse.email}
                    onChange={this.handleEmailChange}
                    required
                  />
                </label>
              </fieldset>
            </section>
            <div className={style.SubmitButton}>
              <button
                type="submit"
                style={this.state.successful ? { background: '#00B16A' } : {}}
                disabled={this.state.successful}
              >
                {this.state.buttonProcessing ? (
                  <div>
                    <span style={{ color: 'white', paddingRight: '5px' }}>
                      Processing
                    </span>
                    <Loader active inline inverted size="small" />
                  </div>
                ) : this.state.successful ? (
                  <span style={{ color: 'white' }}>
                    Sent <Icon inverted name="check" />{' '}
                  </span>
                ) : (
                  'Send My Receipt'
                )}
              </button>
            </div>
            
          </form>
   
            
        
        </div>
        {/* <div style = {{textAlign: 'center'}}>
        Spread the word!
        </div>
        <div className={style.rowIcon}>
          <div className={style.floatCenter}>
        <FacebookShareButton url='parktogive.com' quote='I contributed to United Way by using Park to Give!' hashtag='#Park2Give'>
              <FacebookIcon size={32} round={true}/>
          </FacebookShareButton>
          </div>
          <div className={style.floatCenter}>
          <TwitterShareButton url='parktogive.com' title='Convenient parking in the Vista AND supporting United Way? Yes please!' hashtags={['parktogive','UWMidlands','ColumbiaSC']}>
            <TwitterIcon size={32} round={true}/>
          </TwitterShareButton>
          </div>
        </div> */}
      </div>
      
    );
  }
  private handleEmailChange = async e => {
    this.setState({ email: e.target.value });
  };

  private handleOnSubmit = async e => {
    e.preventDefault();
    this.setState({ buttonProcessing: true });
    this.analyticsService.event(GA_EVENTS.RECEIPT_REQUESTED);
    const response = await this.apiService.sendRecieptInfo(
      this.paymentResponse.localId,
      this.state.email
    );

    if (response.status === 201) {
      // successful
      this.setState({ successful: true, buttonProcessing: false });
    } else {
      // failed
      console.log(response.status);
      this.setState({ buttonProcessing: false });
    }
  };
}
