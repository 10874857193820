/// <reference types="node" />
import { Container } from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import { decorate, injectable, unmanaged } from "inversify";
import 'reflect-metadata';
// Constants
import { TYPES } from '../constants';

// InversifyJS setup
const container: Container = new Container();
const { lazyInject } = getDecorators(container);

// Bindings
import { ParkingStore } from '../stores/ParkingStore';
import { IParkingStore } from '../stores/IParkingStore';
container
  .bind<IParkingStore>(TYPES.ParkingStore)
  .to(ParkingStore)
  .inSingletonScope();

import {DefaultApiInterface,DefaultApi } from '../remoteapi/generated/';
import {Configuration,ConfigurationParameters} from '../remoteapi/generated/'
const configParameters : ConfigurationParameters = {
  basePath : '/api/v1',
  baseOptions : {  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 404; 
  }}
}
const configuration : Configuration = new Configuration(configParameters);
configuration.baseOptions = configParameters.baseOptions;
const remoteApi = new DefaultApi(configuration,'/api/v1');
container.bind<DefaultApiInterface>(TYPES.RemoteAPI).toConstantValue(remoteApi);

import { APIService2 } from '../services/ApiService2';
import { IAPIService2 } from '../services/IAPIService2';
container.bind<IAPIService2>(TYPES.APIService).to(APIService2);

import { IAnalyticsService } from '../services/IAnalyticsService';
import { GAService } from '../services/GAService';
container
  .bind<string>(TYPES.AnalyticService_CONFIG_GA_ID)
  .toConstantValue(process.env.GA_ID_ENV);
container
  .bind<IAnalyticsService>(TYPES.AnalyticService)
  .to(GAService)
  .inSingletonScope();

// End Bindings
export { container, lazyInject };
