exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UwjXsum8OcCb90FzTDIAt{text-align:center}._3bWdOLrqVJFkqqDsWz20OK{display:block!important;margin:auto;max-width:500px;padding:10px 14px;font-size:1em;font-family:Roboto,monospace;box-shadow:0 1px 3px rgba(50,50,93,.14902),0 1px 0 rgba(0,0,0,.0196078)!important;border:0;outline:0;border-radius:4px!important;background:#fff!important}", ""]);

// exports
exports.locals = {
	"center": "UwjXsum8OcCb90FzTDIAt",
	"formStripe": "_3bWdOLrqVJFkqqDsWz20OK"
};