exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3v5N9nS8cYiGE2K8QlkC8K{color:red;padding-left:10px;padding-right:10px;text-align:center}", ""]);

// exports
exports.locals = {
	"Zone12": "_3v5N9nS8cYiGE2K8QlkC8K"
};