import * as React from 'react';
import * as style from './style.css';
import { Header, Menu, Button, Icon } from 'semantic-ui-react';
import * as logo from '../../../../assets/images/logo.png';
import PrivacyPolicyAsync from '../PrivacyPolicy/PrivacyPolicyAsync';
import TermsOfServiceAsync from '../TermsOfService/TosAsync';
import AboutAsync from '../About/AboutAsync';

export interface IMenuBarState {
  menuActive: boolean;
}

export class MenuBar extends React.Component<{}, IMenuBarState> {
  private node;

  constructor(props: {}, context: any) {
    super(props, context);
    this.state = { menuActive: false };
  }

  public componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  public componentWillUnMount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  public render() {
    return (
      <div className={style.Header}>
        <div className={style.Logo}>
          <img src={logo} width="30" height="34.5" />
          <Header as="h2" className={style.HeaderText}>
            ark to Give
          </Header>
          <span
            className={style.MenuContainer}
            ref={node => (this.node = node)}
          >
            <Button
              onClick={this.handleMenuClick}
              active={this.state.menuActive === true}
              className={style.MenuIcon}
            >
              <Icon name="bars" size="large" className={style.BarsIcon} />
            </Button>
            <div style={{ display: 'none' }} id="menu" className={style.Menu}>
              <Menu vertical className={style.MenuUI}>
                <AboutAsync/>
                <PrivacyPolicyAsync />
                <TermsOfServiceAsync />
              </Menu>
            </div>
          </span>
        </div>
      </div>
    );
  }

  private handleMenuClick = e => {
    this.setState({ menuActive: !this.state.menuActive });
    document.getElementById('menu').style.display =
      this.state.menuActive === false ? 'block' : 'none';
  };

  private handleClick = e => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({ menuActive: false });
    document.getElementById('menu').style.display = 'none';
  };
}
