import { IAnalyticEvent } from '../services/IAnalyticsService';

export const GA_EVENTS = {
  QR_CODE_READ: {name:'qr_code_read',cat: 'user-interaction',captureMultiple:false},
  PRICE_BUTTON_PRESS: {name:'price_button_press',cat: 'user-interaction',captureMultiple:true},
  CUSTOM_PRICE_ENTERED: {name:'custom_price_entered',cat: 'user-interaction',captureMultiple:false},
  RECEIPT_REQUESTED: {name:'receipt_requested',cat: 'user-interaction',captureMultiple:true},
  PAYMENT_SUCCESS:  {name:'payment_success',cat: 'user-interaction',captureMultiple:true},
  PAYMENT_FAILED:  {name:'payment_failed',cat: 'user-interaction',captureMultiple:true}
};

export const MODAL_VIEWS = {
  LEARN_MORE :"/learn_more",
  ABOUT : "/about",
  PRIVACY_POLICY : "/privacy_policy",
  TOS : "/tos"

}