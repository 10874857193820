exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._6pj1RUQtg97PVEejA96Ex{color:#fff!important;float:left;margin-top:0!important;padding-top:1px!important;padding-left:2px!important}._1BnyQQRSZKygJCmH4dbfcG{background:#08f}._2Tbi3jl-85UgX2glqcKxhU{background:transparent!important;padding-top:4px;margin-left:auto}._3why0kQys3OIkJMWL5FWB_{background:transparent!important;color:#fff!important;padding:0!important}._1UnHPX7T0tU-ArXj1uLAul{color:#fff;padding-bottom:20px}._1jMFBGu1PThjDXq1ocYovv{text-align:center;padding-top:10px}._3QETHEI78uY_x9UR6LGdL3{font-size:1.3em;text-align:center;line-height:1.6}._3QETHEI78uY_x9UR6LGdL3 a{color:#fff}body{font-size:16px;cursor:pointer}._308Gxr_65JqbHMniZ8gcKy{padding:10px;display:flex;flex-direction:row;align-content:center}._27fZvSe5DP4YyrAadDKt0O{display:\"none\";position:absolute;top:40px;right:10px;z-index:999}._3kC6RgRcb2u7shEl3PXvsf{color:#fff!important;float:left;margin-top:0!important;padding-top:1px!important;padding-left:2px!important;margin-right:0!important}._2ShX-CJ8sLO4kmyPrOozXT{width:10rem!important}._3d6gAKoNErBASlmIRssk-0{color:#fff!important;font-weight:700}@supports (padding:max(0px)){._308Gxr_65JqbHMniZ8gcKy{padding-left:max(12px,env(safe-area-inset-left));padding-right:max(12px,env(safe-area-inset-right))}._27fZvSe5DP4YyrAadDKt0O{right:max(10px,env(safe-area-inset-left))}}", ""]);

// exports
exports.locals = {
	"HeaderText": "_6pj1RUQtg97PVEejA96Ex",
	"Header": "_1BnyQQRSZKygJCmH4dbfcG",
	"MenuContainer": "_2Tbi3jl-85UgX2glqcKxhU",
	"MenuIcon": "_3why0kQys3OIkJMWL5FWB_",
	"InfoBox": "_1UnHPX7T0tU-ArXj1uLAul",
	"HeaderButton": "_1jMFBGu1PThjDXq1ocYovv",
	"Subtitle": "_3QETHEI78uY_x9UR6LGdL3",
	"Logo": "_308Gxr_65JqbHMniZ8gcKy",
	"Menu": "_27fZvSe5DP4YyrAadDKt0O",
	"BarsIcon": "_3kC6RgRcb2u7shEl3PXvsf",
	"MenuUI": "_2ShX-CJ8sLO4kmyPrOozXT",
	"Money": "_3d6gAKoNErBASlmIRssk-0"
};