// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Park To Give
 * Park To Give Web API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthMeResponse
 */
export interface AuthMeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthMeResponse
     */
    authenticated: boolean;
    /**
     * 
     * @type {UserProfile}
     * @memberof AuthMeResponse
     */
    userProfile?: UserProfile;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordResponse
 */
export interface ChangePasswordResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChangePasswordResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordResponse
     */
    errorMessage?: string;
}
/**
 * 
 * @export
 * @interface ClientInfo
 */
export interface ClientInfo {
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    stripeKey?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientInfo
     */
    fundsRaisedDollars?: number;
}
/**
 * 
 * @export
 * @interface CompletePasswordResetRequest
 */
export interface CompletePasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompletePasswordResetRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof CompletePasswordResetRequest
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface CompletePasswordResetResponse
 */
export interface CompletePasswordResetResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CompletePasswordResetResponse
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompletePasswordResetResponse
     */
    errorMessage?: string;
}
/**
 * 
 * @export
 * @interface InitPasswordResetRequest
 */
export interface InitPasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof InitPasswordResetRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    authenticated: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof LoginResponse
     */
    userProfile?: UserProfile;
}
/**
 * 
 * @export
 * @interface OverviewPaymentsSummary
 */
export interface OverviewPaymentsSummary {
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last365DaysPaidCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last365DaysPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last90DaysPaidCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last90DaysPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last30DaysPaidCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last30DaysPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last7DaysPaidCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    last7DaysPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    yesterdayPaidCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    yesterdayPaidAmt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    todayPaidCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof OverviewPaymentsSummary
     */
    todayPaidAmt?: number;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    stripeToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    zoneId?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    chargeAmountDollars?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    tagNum?: string;
}
/**
 * 
 * @export
 * @interface PaymentInfo
 */
export interface PaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof PaymentInfo
     */
    paymentId?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInfo
     */
    paymentAmt?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfo
     */
    zoneId?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInfo
     */
    paymentDateUTC?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfo
     */
    paymentDateZoneLocal?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInfo
     */
    reservedTillUTC?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfo
     */
    reservedTillZoneLocal?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentInfo
     */
    tagNum?: string;
}
/**
 * 
 * @export
 * @interface PaymentResponse
 */
export interface PaymentResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    result: PaymentResponseResultEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    paymentFailureErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    localId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    charityAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    parkingAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    totalAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    zoneId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    reservedTill?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentResponse
     */
    tagNum?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentResponseResultEnum {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

/**
 * 
 * @export
 * @interface PeriodPaymentsSummary
 */
export interface PeriodPaymentsSummary {
    /**
     * 
     * @type {string}
     * @memberof PeriodPaymentsSummary
     */
    periodLabel?: string;
    /**
     * 
     * @type {number}
     * @memberof PeriodPaymentsSummary
     */
    peroid?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodPaymentsSummary
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodPaymentsSummary
     */
    periodBeginDate?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodPaymentsSummary
     */
    peroidEndDate?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodPaymentsSummary
     */
    cnt?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodPaymentsSummary
     */
    total?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Peroid {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    YEARLY = 'YEARLY'
}

/**
 * 
 * @export
 * @interface ReceiptInfo
 */
export interface ReceiptInfo {
    /**
     * 
     * @type {string}
     * @memberof ReceiptInfo
     */
    localId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptInfo
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface UpsertUserRequest
 */
export interface UpsertUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertUserRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertUserRequest
     */
    admin?: boolean;
}
/**
 * 
 * @export
 * @interface UpsertUserResponse
 */
export interface UpsertUserResponse {
    /**
     * 
     * @type {UserProfile}
     * @memberof UpsertUserResponse
     */
    userProfile: UserProfile;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfile
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface ZoneInfo
 */
export interface ZoneInfo {
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    zoneUUID?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    zoneId?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    locationStreetAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    locationState?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    locationCity?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    locationZip5?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    directions?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneInfo
     */
    numSpaces?: number;
    /**
     * 
     * @type {number}
     * @memberof ZoneInfo
     */
    currentPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ZoneInfo
     */
    reservedNow?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ZoneInfo
     */
    reservedScheduleDescr?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ZoneInfo
     */
    nextReservationTimeUTC?: number;
    /**
     * 
     * @type {number}
     * @memberof ZoneInfo
     */
    nextReservationAvailableTillUTC?: number;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    sponsor?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneInfo
     */
    sponsorWebsite?: string;
    /**
     * 
     * @type {number}
     * @memberof ZoneInfo
     */
    sponsorGiveShare?: number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add user
         * @param {UpsertUserRequest} upsertUserRequest Add  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(upsertUserRequest: UpsertUserRequest, options: any = {}): RequestArgs {
            // verify required parameter 'upsertUserRequest' is not null or undefined
            if (upsertUserRequest === null || upsertUserRequest === undefined) {
                throw new RequiredError('upsertUserRequest','Required parameter upsertUserRequest was null or undefined when calling addUser.');
            }
            const localVarPath = `/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpsertUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(upsertUserRequest !== undefined ? upsertUserRequest : {}) : (upsertUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Am I authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amIAuthenticated(options: any = {}): RequestArgs {
            const localVarPath = `/auth/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change my password
         * @param {ChangePasswordRequest} changePasswordRequest Change user password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyPassword(changePasswordRequest: ChangePasswordRequest, options: any = {}): RequestArgs {
            // verify required parameter 'changePasswordRequest' is not null or undefined
            if (changePasswordRequest === null || changePasswordRequest === undefined) {
                throw new RequiredError('changePasswordRequest','Required parameter changePasswordRequest was null or undefined when calling changeMyPassword.');
            }
            const localVarPath = `/user/me/password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChangePasswordRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changePasswordRequest !== undefined ? changePasswordRequest : {}) : (changePasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change user password
         * @param {string} userId userId
         * @param {ChangePasswordRequest} changePasswordRequest Change user password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userId: string, changePasswordRequest: ChangePasswordRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling changePassword.');
            }
            // verify required parameter 'changePasswordRequest' is not null or undefined
            if (changePasswordRequest === null || changePasswordRequest === undefined) {
                throw new RequiredError('changePasswordRequest','Required parameter changePasswordRequest was null or undefined when calling changePassword.');
            }
            const localVarPath = `/user/{userId}/password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ChangePasswordRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(changePasswordRequest !== undefined ? changePasswordRequest : {}) : (changePasswordRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete Password Reset
         * @param {CompletePasswordResetRequest} completePasswordResetRequest Complete Password Reset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeResetPassword(completePasswordResetRequest: CompletePasswordResetRequest, options: any = {}): RequestArgs {
            // verify required parameter 'completePasswordResetRequest' is not null or undefined
            if (completePasswordResetRequest === null || completePasswordResetRequest === undefined) {
                throw new RequiredError('completePasswordResetRequest','Required parameter completePasswordResetRequest was null or undefined when calling completeResetPassword.');
            }
            const localVarPath = `/user/password/reset/complete`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"CompletePasswordResetRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(completePasswordResetRequest !== undefined ? completePasswordResetRequest : {}) : (completePasswordResetRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user to provider
         * @param {string} providerId providerId
         * @param {string} userId userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(providerId: string, userId: string, options: any = {}): RequestArgs {
            // verify required parameter 'providerId' is not null or undefined
            if (providerId === null || providerId === undefined) {
                throw new RequiredError('providerId','Required parameter providerId was null or undefined when calling deleteUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/provider/{providerId}/users/{userId}`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientInfo(options: any = {}): RequestArgs {
            const localVarPath = `/clientinfo`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get My Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyZoneInfo(options: any = {}): RequestArgs {
            const localVarPath = `/zone/my`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stripe api token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayConfig(options: any = {}): RequestArgs {
            const localVarPath = `/pay`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get provider users
         * @param {string} providerId providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderUsers(providerId: string, options: any = {}): RequestArgs {
            // verify required parameter 'providerId' is not null or undefined
            if (providerId === null || providerId === undefined) {
                throw new RequiredError('providerId','Required parameter providerId was null or undefined when calling getProviderUsers.');
            }
            const localVarPath = `/provider/{providerId}/users`
                .replace(`{${"providerId"}}`, encodeURIComponent(String(providerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Zone Info
         * @param {string} zoneId zoneId of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneInfo(zoneId: string, options: any = {}): RequestArgs {
            // verify required parameter 'zoneId' is not null or undefined
            if (zoneId === null || zoneId === undefined) {
                throw new RequiredError('zoneId','Required parameter zoneId was null or undefined when calling getZoneInfo.');
            }
            const localVarPath = `/zone/{zoneId}`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Zone Info By UUID
         * @param {string} zoneUUID zoneUUID of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneInfoByUUID(zoneUUID: string, options: any = {}): RequestArgs {
            // verify required parameter 'zoneUUID' is not null or undefined
            if (zoneUUID === null || zoneUUID === undefined) {
                throw new RequiredError('zoneUUID','Required parameter zoneUUID was null or undefined when calling getZoneInfoByUUID.');
            }
            const localVarPath = `/zone/uuid/{zoneUUID}`
                .replace(`{${"zoneUUID"}}`, encodeURIComponent(String(zoneUUID)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Quick Payment Summary for Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneOverviewPaymentsSummary(zoneUUID: string, options: any = {}): RequestArgs {
            // verify required parameter 'zoneUUID' is not null or undefined
            if (zoneUUID === null || zoneUUID === undefined) {
                throw new RequiredError('zoneUUID','Required parameter zoneUUID was null or undefined when calling getZoneOverviewPaymentsSummary.');
            }
            const localVarPath = `/zone/{zoneUUID}/payments/summary/overview`
                .replace(`{${"zoneUUID"}}`, encodeURIComponent(String(zoneUUID)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payments For Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {boolean} [validReservation] valid reservations only
         * @param {number} [page] page number
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentInfo(zoneUUID: string, validReservation?: boolean, page?: number, size?: number, options: any = {}): RequestArgs {
            // verify required parameter 'zoneUUID' is not null or undefined
            if (zoneUUID === null || zoneUUID === undefined) {
                throw new RequiredError('zoneUUID','Required parameter zoneUUID was null or undefined when calling getZonePaymentInfo.');
            }
            const localVarPath = `/zone/{zoneUUID}/payments`
                .replace(`{${"zoneUUID"}}`, encodeURIComponent(String(zoneUUID)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (validReservation !== undefined) {
                localVarQueryParameter['validReservation'] = validReservation;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payments Count For Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {boolean} [validReservation] valid reservations only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentInfoCount(zoneUUID: string, validReservation?: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'zoneUUID' is not null or undefined
            if (zoneUUID === null || zoneUUID === undefined) {
                throw new RequiredError('zoneUUID','Required parameter zoneUUID was null or undefined when calling getZonePaymentInfoCount.');
            }
            const localVarPath = `/zone/{zoneUUID}/payments/count`
                .replace(`{${"zoneUUID"}}`, encodeURIComponent(String(zoneUUID)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (validReservation !== undefined) {
                localVarQueryParameter['validReservation'] = validReservation;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Payment Summary for Zone
         * @param {string} zoneUUID uuid of zone
         * @param {Peroid} peroid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentsSummaryByPeroid(zoneUUID: string, peroid: Peroid, options: any = {}): RequestArgs {
            // verify required parameter 'zoneUUID' is not null or undefined
            if (zoneUUID === null || zoneUUID === undefined) {
                throw new RequiredError('zoneUUID','Required parameter zoneUUID was null or undefined when calling getZonePaymentsSummaryByPeroid.');
            }
            // verify required parameter 'peroid' is not null or undefined
            if (peroid === null || peroid === undefined) {
                throw new RequiredError('peroid','Required parameter peroid was null or undefined when calling getZonePaymentsSummaryByPeroid.');
            }
            const localVarPath = `/zone/{zoneUUID}/payments/summary/{peroid}`
                .replace(`{${"zoneUUID"}}`, encodeURIComponent(String(zoneUUID)))
                .replace(`{${"peroid"}}`, encodeURIComponent(String(peroid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Init Password Reset
         * @param {InitPasswordResetRequest} initPasswordResetRequest Init Password Reset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initResetPassword(initPasswordResetRequest: InitPasswordResetRequest, options: any = {}): RequestArgs {
            // verify required parameter 'initPasswordResetRequest' is not null or undefined
            if (initPasswordResetRequest === null || initPasswordResetRequest === undefined) {
                throw new RequiredError('initPasswordResetRequest','Required parameter initPasswordResetRequest was null or undefined when calling initResetPassword.');
            }
            const localVarPath = `/user/password/reset/init`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"InitPasswordResetRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(initPasswordResetRequest !== undefined ? initPasswordResetRequest : {}) : (initPasswordResetRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options: any = {}): RequestArgs {
            // verify required parameter 'loginRequest' is not null or undefined
            if (loginRequest === null || loginRequest === undefined) {
                throw new RequiredError('loginRequest','Required parameter loginRequest was null or undefined when calling login.');
            }
            const localVarPath = `/auth/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"LoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(loginRequest !== undefined ? loginRequest : {}) : (loginRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): RequestArgs {
            const localVarPath = `/auth/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request receipt
         * @param {ReceiptInfo} receiptInfo request receipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReceipt(receiptInfo: ReceiptInfo, options: any = {}): RequestArgs {
            // verify required parameter 'receiptInfo' is not null or undefined
            if (receiptInfo === null || receiptInfo === undefined) {
                throw new RequiredError('receiptInfo','Required parameter receiptInfo was null or undefined when calling requestReceipt.');
            }
            const localVarPath = `/receipt`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ReceiptInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(receiptInfo !== undefined ? receiptInfo : {}) : (receiptInfo || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find closest zone to provided longitude and latitude
         * @param {number} latitude Latitude
         * @param {number} longitude Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNearestZone(latitude: number, longitude: number, options: any = {}): RequestArgs {
            // verify required parameter 'latitude' is not null or undefined
            if (latitude === null || latitude === undefined) {
                throw new RequiredError('latitude','Required parameter latitude was null or undefined when calling searchNearestZone.');
            }
            // verify required parameter 'longitude' is not null or undefined
            if (longitude === null || longitude === undefined) {
                throw new RequiredError('longitude','Required parameter longitude was null or undefined when calling searchNearestZone.');
            }
            const localVarPath = `/zone/search/nearest`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit payment
         * @param {Payment} payment Payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPayment(payment: Payment, options: any = {}): RequestArgs {
            // verify required parameter 'payment' is not null or undefined
            if (payment === null || payment === undefined) {
                throw new RequiredError('payment','Required parameter payment was null or undefined when calling submitPayment.');
            }
            const localVarPath = `/pay`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Payment" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(payment !== undefined ? payment : {}) : (payment || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user
         * @param {string} userId userId
         * @param {UpsertUserRequest} upsertUserRequest Update  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, upsertUserRequest: UpsertUserRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUser.');
            }
            // verify required parameter 'upsertUserRequest' is not null or undefined
            if (upsertUserRequest === null || upsertUserRequest === undefined) {
                throw new RequiredError('upsertUserRequest','Required parameter upsertUserRequest was null or undefined when calling updateUser.');
            }
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpsertUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(upsertUserRequest !== undefined ? upsertUserRequest : {}) : (upsertUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add user
         * @param {UpsertUserRequest} upsertUserRequest Add  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(upsertUserRequest: UpsertUserRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertUserResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).addUser(upsertUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Am I authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amIAuthenticated(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMeResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).amIAuthenticated(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change my password
         * @param {ChangePasswordRequest} changePasswordRequest Change user password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyPassword(changePasswordRequest: ChangePasswordRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).changeMyPassword(changePasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Change user password
         * @param {string} userId userId
         * @param {ChangePasswordRequest} changePasswordRequest Change user password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userId: string, changePasswordRequest: ChangePasswordRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).changePassword(userId, changePasswordRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Complete Password Reset
         * @param {CompletePasswordResetRequest} completePasswordResetRequest Complete Password Reset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeResetPassword(completePasswordResetRequest: CompletePasswordResetRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletePasswordResetResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).completeResetPassword(completePasswordResetRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add user to provider
         * @param {string} providerId providerId
         * @param {string} userId userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(providerId: string, userId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteUser(providerId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get client info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientInfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getClientInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get My Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyZoneInfo(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoneInfo>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getMyZoneInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get stripe api token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayConfig(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getPayConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get provider users
         * @param {string} providerId providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderUsers(providerId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserProfile>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getProviderUsers(providerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Zone Info
         * @param {string} zoneId zoneId of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneInfo(zoneId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getZoneInfo(zoneId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Zone Info By UUID
         * @param {string} zoneUUID zoneUUID of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneInfoByUUID(zoneUUID: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getZoneInfoByUUID(zoneUUID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Quick Payment Summary for Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneOverviewPaymentsSummary(zoneUUID: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverviewPaymentsSummary> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getZoneOverviewPaymentsSummary(zoneUUID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Payments For Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {boolean} [validReservation] valid reservations only
         * @param {number} [page] page number
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentInfo(zoneUUID: string, validReservation?: boolean, page?: number, size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentInfo>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getZonePaymentInfo(zoneUUID, validReservation, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Payments Count For Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {boolean} [validReservation] valid reservations only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentInfoCount(zoneUUID: string, validReservation?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getZonePaymentInfoCount(zoneUUID, validReservation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Payment Summary for Zone
         * @param {string} zoneUUID uuid of zone
         * @param {Peroid} peroid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentsSummaryByPeroid(zoneUUID: string, peroid: Peroid, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeriodPaymentsSummary>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getZonePaymentsSummaryByPeroid(zoneUUID, peroid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Init Password Reset
         * @param {InitPasswordResetRequest} initPasswordResetRequest Init Password Reset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initResetPassword(initPasswordResetRequest: InitPasswordResetRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).initResetPassword(initPasswordResetRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).login(loginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).logout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request receipt
         * @param {ReceiptInfo} receiptInfo request receipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReceipt(receiptInfo: ReceiptInfo, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).requestReceipt(receiptInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find closest zone to provided longitude and latitude
         * @param {number} latitude Latitude
         * @param {number} longitude Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNearestZone(latitude: number, longitude: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneInfo> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).searchNearestZone(latitude, longitude, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submit payment
         * @param {Payment} payment Payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPayment(payment: Payment, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).submitPayment(payment, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update user
         * @param {string} userId userId
         * @param {UpsertUserRequest} upsertUserRequest Update  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, upsertUserRequest: UpsertUserRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertUserResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateUser(userId, upsertUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add user
         * @param {UpsertUserRequest} upsertUserRequest Add  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(upsertUserRequest: UpsertUserRequest, options?: any) {
            return DefaultApiFp(configuration).addUser(upsertUserRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Am I authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amIAuthenticated(options?: any) {
            return DefaultApiFp(configuration).amIAuthenticated(options)(axios, basePath);
        },
        /**
         * 
         * @summary Change my password
         * @param {ChangePasswordRequest} changePasswordRequest Change user password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMyPassword(changePasswordRequest: ChangePasswordRequest, options?: any) {
            return DefaultApiFp(configuration).changeMyPassword(changePasswordRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Change user password
         * @param {string} userId userId
         * @param {ChangePasswordRequest} changePasswordRequest Change user password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userId: string, changePasswordRequest: ChangePasswordRequest, options?: any) {
            return DefaultApiFp(configuration).changePassword(userId, changePasswordRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Complete Password Reset
         * @param {CompletePasswordResetRequest} completePasswordResetRequest Complete Password Reset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeResetPassword(completePasswordResetRequest: CompletePasswordResetRequest, options?: any) {
            return DefaultApiFp(configuration).completeResetPassword(completePasswordResetRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Add user to provider
         * @param {string} providerId providerId
         * @param {string} userId userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(providerId: string, userId: string, options?: any) {
            return DefaultApiFp(configuration).deleteUser(providerId, userId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get client info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientInfo(options?: any) {
            return DefaultApiFp(configuration).getClientInfo(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get My Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyZoneInfo(options?: any) {
            return DefaultApiFp(configuration).getMyZoneInfo(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get stripe api token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayConfig(options?: any) {
            return DefaultApiFp(configuration).getPayConfig(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get provider users
         * @param {string} providerId providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderUsers(providerId: string, options?: any) {
            return DefaultApiFp(configuration).getProviderUsers(providerId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Zone Info
         * @param {string} zoneId zoneId of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneInfo(zoneId: string, options?: any) {
            return DefaultApiFp(configuration).getZoneInfo(zoneId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Zone Info By UUID
         * @param {string} zoneUUID zoneUUID of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneInfoByUUID(zoneUUID: string, options?: any) {
            return DefaultApiFp(configuration).getZoneInfoByUUID(zoneUUID, options)(axios, basePath);
        },
        /**
         * 
         * @summary Quick Payment Summary for Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneOverviewPaymentsSummary(zoneUUID: string, options?: any) {
            return DefaultApiFp(configuration).getZoneOverviewPaymentsSummary(zoneUUID, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Payments For Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {boolean} [validReservation] valid reservations only
         * @param {number} [page] page number
         * @param {number} [size] page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentInfo(zoneUUID: string, validReservation?: boolean, page?: number, size?: number, options?: any) {
            return DefaultApiFp(configuration).getZonePaymentInfo(zoneUUID, validReservation, page, size, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get Payments Count For Zone
         * @param {string} zoneUUID zoneUUID of zone
         * @param {boolean} [validReservation] valid reservations only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentInfoCount(zoneUUID: string, validReservation?: boolean, options?: any) {
            return DefaultApiFp(configuration).getZonePaymentInfoCount(zoneUUID, validReservation, options)(axios, basePath);
        },
        /**
         * 
         * @summary Payment Summary for Zone
         * @param {string} zoneUUID uuid of zone
         * @param {Peroid} peroid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZonePaymentsSummaryByPeroid(zoneUUID: string, peroid: Peroid, options?: any) {
            return DefaultApiFp(configuration).getZonePaymentsSummaryByPeroid(zoneUUID, peroid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Init Password Reset
         * @param {InitPasswordResetRequest} initPasswordResetRequest Init Password Reset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initResetPassword(initPasswordResetRequest: InitPasswordResetRequest, options?: any) {
            return DefaultApiFp(configuration).initResetPassword(initPasswordResetRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Login
         * @param {LoginRequest} loginRequest login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any) {
            return DefaultApiFp(configuration).login(loginRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return DefaultApiFp(configuration).logout(options)(axios, basePath);
        },
        /**
         * 
         * @summary Request receipt
         * @param {ReceiptInfo} receiptInfo request receipt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReceipt(receiptInfo: ReceiptInfo, options?: any) {
            return DefaultApiFp(configuration).requestReceipt(receiptInfo, options)(axios, basePath);
        },
        /**
         * 
         * @summary Find closest zone to provided longitude and latitude
         * @param {number} latitude Latitude
         * @param {number} longitude Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNearestZone(latitude: number, longitude: number, options?: any) {
            return DefaultApiFp(configuration).searchNearestZone(latitude, longitude, options)(axios, basePath);
        },
        /**
         * 
         * @summary Submit payment
         * @param {Payment} payment Payment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPayment(payment: Payment, options?: any) {
            return DefaultApiFp(configuration).submitPayment(payment, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update user
         * @param {string} userId userId
         * @param {UpsertUserRequest} upsertUserRequest Update  user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, upsertUserRequest: UpsertUserRequest, options?: any) {
            return DefaultApiFp(configuration).updateUser(userId, upsertUserRequest, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Add user
     * @param {UpsertUserRequest} upsertUserRequest Add  user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    addUser(upsertUserRequest: UpsertUserRequest, options?: any): AxiosPromise<UpsertUserResponse>;

    /**
     * 
     * @summary Am I authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    amIAuthenticated(options?: any): AxiosPromise<AuthMeResponse>;

    /**
     * 
     * @summary Change my password
     * @param {ChangePasswordRequest} changePasswordRequest Change user password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    changeMyPassword(changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<ChangePasswordResponse>;

    /**
     * 
     * @summary Change user password
     * @param {string} userId userId
     * @param {ChangePasswordRequest} changePasswordRequest Change user password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    changePassword(userId: string, changePasswordRequest: ChangePasswordRequest, options?: any): AxiosPromise<ChangePasswordResponse>;

    /**
     * 
     * @summary Complete Password Reset
     * @param {CompletePasswordResetRequest} completePasswordResetRequest Complete Password Reset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    completeResetPassword(completePasswordResetRequest: CompletePasswordResetRequest, options?: any): AxiosPromise<CompletePasswordResetResponse>;

    /**
     * 
     * @summary Add user to provider
     * @param {string} providerId providerId
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteUser(providerId: string, userId: string, options?: any): AxiosPromise<{}>;

    /**
     * 
     * @summary Get client info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getClientInfo(options?: any): AxiosPromise<ClientInfo>;

    /**
     * 
     * @summary Get My Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getMyZoneInfo(options?: any): AxiosPromise<Array<ZoneInfo>>;

    /**
     * 
     * @summary Get stripe api token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getPayConfig(options?: any): AxiosPromise<string>;

    /**
     * 
     * @summary Get provider users
     * @param {string} providerId providerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getProviderUsers(providerId: string, options?: any): AxiosPromise<Array<UserProfile>>;

    /**
     * 
     * @summary Get Zone Info
     * @param {string} zoneId zoneId of zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getZoneInfo(zoneId: string, options?: any): AxiosPromise<ZoneInfo>;

    /**
     * 
     * @summary Get Zone Info By UUID
     * @param {string} zoneUUID zoneUUID of zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getZoneInfoByUUID(zoneUUID: string, options?: any): AxiosPromise<ZoneInfo>;

    /**
     * 
     * @summary Quick Payment Summary for Zone
     * @param {string} zoneUUID zoneUUID of zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getZoneOverviewPaymentsSummary(zoneUUID: string, options?: any): AxiosPromise<OverviewPaymentsSummary>;

    /**
     * 
     * @summary Get Payments For Zone
     * @param {string} zoneUUID zoneUUID of zone
     * @param {boolean} [validReservation] valid reservations only
     * @param {number} [page] page number
     * @param {number} [size] page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getZonePaymentInfo(zoneUUID: string, validReservation?: boolean, page?: number, size?: number, options?: any): AxiosPromise<Array<PaymentInfo>>;

    /**
     * 
     * @summary Get Payments Count For Zone
     * @param {string} zoneUUID zoneUUID of zone
     * @param {boolean} [validReservation] valid reservations only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getZonePaymentInfoCount(zoneUUID: string, validReservation?: boolean, options?: any): AxiosPromise<number>;

    /**
     * 
     * @summary Payment Summary for Zone
     * @param {string} zoneUUID uuid of zone
     * @param {Peroid} peroid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getZonePaymentsSummaryByPeroid(zoneUUID: string, peroid: Peroid, options?: any): AxiosPromise<Array<PeriodPaymentsSummary>>;

    /**
     * 
     * @summary Init Password Reset
     * @param {InitPasswordResetRequest} initPasswordResetRequest Init Password Reset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    initResetPassword(initPasswordResetRequest: InitPasswordResetRequest, options?: any): AxiosPromise<{}>;

    /**
     * 
     * @summary Login
     * @param {LoginRequest} loginRequest login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    login(loginRequest: LoginRequest, options?: any): AxiosPromise<LoginResponse>;

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    logout(options?: any): AxiosPromise<{}>;

    /**
     * 
     * @summary Request receipt
     * @param {ReceiptInfo} receiptInfo request receipt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    requestReceipt(receiptInfo: ReceiptInfo, options?: any): AxiosPromise<{}>;

    /**
     * 
     * @summary Find closest zone to provided longitude and latitude
     * @param {number} latitude Latitude
     * @param {number} longitude Longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    searchNearestZone(latitude: number, longitude: number, options?: any): AxiosPromise<ZoneInfo>;

    /**
     * 
     * @summary Submit payment
     * @param {Payment} payment Payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    submitPayment(payment: Payment, options?: any): AxiosPromise<PaymentResponse>;

    /**
     * 
     * @summary Update user
     * @param {string} userId userId
     * @param {UpsertUserRequest} upsertUserRequest Update  user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateUser(userId: string, upsertUserRequest: UpsertUserRequest, options?: any): AxiosPromise<UpsertUserResponse>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Add user
     * @param {UpsertUserRequest} upsertUserRequest Add  user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public addUser(upsertUserRequest: UpsertUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).addUser(upsertUserRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Am I authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public amIAuthenticated(options?: any) {
        return DefaultApiFp(this.configuration).amIAuthenticated(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Change my password
     * @param {ChangePasswordRequest} changePasswordRequest Change user password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changeMyPassword(changePasswordRequest: ChangePasswordRequest, options?: any) {
        return DefaultApiFp(this.configuration).changeMyPassword(changePasswordRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Change user password
     * @param {string} userId userId
     * @param {ChangePasswordRequest} changePasswordRequest Change user password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public changePassword(userId: string, changePasswordRequest: ChangePasswordRequest, options?: any) {
        return DefaultApiFp(this.configuration).changePassword(userId, changePasswordRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Complete Password Reset
     * @param {CompletePasswordResetRequest} completePasswordResetRequest Complete Password Reset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public completeResetPassword(completePasswordResetRequest: CompletePasswordResetRequest, options?: any) {
        return DefaultApiFp(this.configuration).completeResetPassword(completePasswordResetRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Add user to provider
     * @param {string} providerId providerId
     * @param {string} userId userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteUser(providerId: string, userId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteUser(providerId, userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get client info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClientInfo(options?: any) {
        return DefaultApiFp(this.configuration).getClientInfo(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get My Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMyZoneInfo(options?: any) {
        return DefaultApiFp(this.configuration).getMyZoneInfo(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get stripe api token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPayConfig(options?: any) {
        return DefaultApiFp(this.configuration).getPayConfig(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get provider users
     * @param {string} providerId providerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProviderUsers(providerId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProviderUsers(providerId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Zone Info
     * @param {string} zoneId zoneId of zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZoneInfo(zoneId: string, options?: any) {
        return DefaultApiFp(this.configuration).getZoneInfo(zoneId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Zone Info By UUID
     * @param {string} zoneUUID zoneUUID of zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZoneInfoByUUID(zoneUUID: string, options?: any) {
        return DefaultApiFp(this.configuration).getZoneInfoByUUID(zoneUUID, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Quick Payment Summary for Zone
     * @param {string} zoneUUID zoneUUID of zone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZoneOverviewPaymentsSummary(zoneUUID: string, options?: any) {
        return DefaultApiFp(this.configuration).getZoneOverviewPaymentsSummary(zoneUUID, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Payments For Zone
     * @param {string} zoneUUID zoneUUID of zone
     * @param {boolean} [validReservation] valid reservations only
     * @param {number} [page] page number
     * @param {number} [size] page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZonePaymentInfo(zoneUUID: string, validReservation?: boolean, page?: number, size?: number, options?: any) {
        return DefaultApiFp(this.configuration).getZonePaymentInfo(zoneUUID, validReservation, page, size, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get Payments Count For Zone
     * @param {string} zoneUUID zoneUUID of zone
     * @param {boolean} [validReservation] valid reservations only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZonePaymentInfoCount(zoneUUID: string, validReservation?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getZonePaymentInfoCount(zoneUUID, validReservation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Payment Summary for Zone
     * @param {string} zoneUUID uuid of zone
     * @param {Peroid} peroid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getZonePaymentsSummaryByPeroid(zoneUUID: string, peroid: Peroid, options?: any) {
        return DefaultApiFp(this.configuration).getZonePaymentsSummaryByPeroid(zoneUUID, peroid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Init Password Reset
     * @param {InitPasswordResetRequest} initPasswordResetRequest Init Password Reset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public initResetPassword(initPasswordResetRequest: InitPasswordResetRequest, options?: any) {
        return DefaultApiFp(this.configuration).initResetPassword(initPasswordResetRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Login
     * @param {LoginRequest} loginRequest login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(loginRequest: LoginRequest, options?: any) {
        return DefaultApiFp(this.configuration).login(loginRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logout(options?: any) {
        return DefaultApiFp(this.configuration).logout(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Request receipt
     * @param {ReceiptInfo} receiptInfo request receipt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public requestReceipt(receiptInfo: ReceiptInfo, options?: any) {
        return DefaultApiFp(this.configuration).requestReceipt(receiptInfo, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Find closest zone to provided longitude and latitude
     * @param {number} latitude Latitude
     * @param {number} longitude Longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public searchNearestZone(latitude: number, longitude: number, options?: any) {
        return DefaultApiFp(this.configuration).searchNearestZone(latitude, longitude, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Submit payment
     * @param {Payment} payment Payment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitPayment(payment: Payment, options?: any) {
        return DefaultApiFp(this.configuration).submitPayment(payment, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update user
     * @param {string} userId userId
     * @param {UpsertUserRequest} upsertUserRequest Update  user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(userId: string, upsertUserRequest: UpsertUserRequest, options?: any) {
        return DefaultApiFp(this.configuration).updateUser(userId, upsertUserRequest, options)(this.axios, this.basePath);
    }

}


