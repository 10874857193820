import * as React from 'react';
import * as style from './style.css';
import { injectStripe } from 'react-stripe-elements';
import { CardElementStripe } from '../../components/CardElementStripe';
import PaymentRequestButton from '../../components/PaymentRequestButton';
import { lazyInject } from '../../utils/inversify.config';
import { TYPES } from '../../constants';
import { IParkingStore } from '../../stores/IParkingStore';
import { observer } from 'mobx-react';
import { IAPIService2 } from '../../services/IAPIService2';
import { PaymentResponse, PaymentResponseResultEnum } from '../../remoteapi/generated/api';
import { ResultTypes } from '../../../shared/constants/ResultTypes';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { ReactStripeElements } from 'react-stripe-elements';
import { PaymentFailureMessage } from '../PaymentFailureMessage';
import { IAnalyticsService} from '../../services/IAnalyticsService';
import { GA_EVENTS } from '../../constants/gaEvents';


export interface IPaymentFormProps
  extends ReactStripeElements.InjectedStripeProps,
    RouteComponentProps<any> {}

export interface IPaymentFormState {
  submitDisabled: boolean;
  processing: boolean;
  paymentFailed: boolean;
  errorMessage: string;
}

@observer
class PaymentFormComponent extends React.Component<
  IPaymentFormProps,
  IPaymentFormState
> {
  @lazyInject(TYPES.APIService) private apiService: IAPIService2;
  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  @lazyInject(TYPES.AnalyticService) private analyticsService: IAnalyticsService;


  constructor(props: IPaymentFormProps, context: any) {
    super(props, context);
    this.state = {
      submitDisabled: false,
      processing: false,
      paymentFailed: false,
      errorMessage: ''
    };
    
  }

  public render() {
    if(this.parkingStore.tagNumber.length> 4) 
    return (
      <div>
        <PaymentFailureMessage displayMessage={this.state.paymentFailed} errorMessage={this.state.errorMessage} />
        <PaymentRequestButton/>
        <div className={style.CardForm}>
          <form id="payment-form" onSubmit={this.handleOnSubmit}>
            <section>
              <div
                className="payment-info card visible"
                style={{ paddingTop: 10 }}
              >
                <CardElementStripe />
              </div>
            </section>
            <div className={style.PaymentButton}>
              <button
                type="submit"
                disabled={
                  (this.parkingStore.selectedPrice !== 0 && this.parkingStore.selectedPrice > 100) || this.parkingStore.tagNumber.length < 5
                      ? true
                      : this.state.submitDisabled
                }
              >
                {this.state.processing ? (
                  <div>
                    <span style={{ color: 'white', paddingRight: '5px' }}>
                      Processing
                    </span>
                    <Loader active inline inverted size="small" />
                  </div>
                ) : this.parkingStore.selectedPrice !== 0 ? (
                  this.parkingStore.selectedPrice > 100 ? (
                    'Please Select a Lower Amount'
                  ) : (
                    'Pay $' + this.parkingStore.selectedPrice
                  )
                ) : (
                  'Please Select an Amount'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
    else return (<div/>);
  }

  private handleOnSubmit = async e => {
    e.preventDefault();
    // Ensure no double clicks
    this.setState({ submitDisabled: true, paymentFailed: false });
    // Attempt to create token
    const { token } = await this.props.stripe.createToken();
    // If token creation successful, process token
    if (token !== undefined) {
      this.setState({ processing: true });
      const response: PaymentResponse = await this.apiService.sendPaymentInfo(
        token.id,
        this.parkingStore.zoneInfo.zoneId,
        this.parkingStore.tagNumber,
        this.parkingStore.selectedPrice,
        null
      );

      if (response.result === PaymentResponseResultEnum.SUCCESS) {
        // Report to the browser that the payment was successful, prompting
        // it to close the browser payment interface.
        this.analyticsService.successfulParkingTx(response.totalAmount,response.localId,response.zoneId);
        this.analyticsService.event(GA_EVENTS.PAYMENT_SUCCESS);
        this.props.history.push('/confirmation', { paymentResponse: response });
      } else {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        this.analyticsService.event(GA_EVENTS.PAYMENT_FAILED);
        this.setState({
          submitDisabled: false,
          processing: false,
          paymentFailed: true,
          errorMessage: response.paymentFailureErrorMessage
        });
      }
    } else {
      this.setState({ submitDisabled: false });
    }
  };
}

export const PaymentForm = withRouter(injectStripe(PaymentFormComponent));
