import * as React from 'react';
import { MainHeader } from '../../components/MainHeader';
import { PaymentForm } from '../../components/PaymentForm';
import { FixedPriceSelector } from '../../components/FixedPriceSelector';
import { RouteComponentProps } from 'react-router-dom';
import { IParkingStore } from '../../stores/IParkingStore';
import { IAPIService2 } from  '../../services/IAPIService2';
import { lazyInject } from '../../utils/inversify.config';
import { TYPES } from '../../constants';
import { Elements } from 'react-stripe-elements';
import { GA_EVENTS } from '../../constants/gaEvents';
import { IAnalyticsService } from '../../services/IAnalyticsService';
import {ParkingDetails } from '../../components/ParkingDetails';
import {ReservedMessage} from '../../components/ReservedMessage';
import { observer } from 'mobx-react';
import * as style from './style.css';

interface IMatchParams {
  id: string;
}

export interface IHomeProps extends RouteComponentProps<IMatchParams> {}
@observer
export class Home extends React.Component<IHomeProps, {}> {
  @lazyInject(TYPES.AnalyticService)
  private analyticsService: IAnalyticsService;

  @lazyInject(TYPES.ParkingStore)
  private parkingStore: IParkingStore;

  @lazyInject(TYPES.APIService) private apiService: IAPIService2;

  constructor(props: IHomeProps, context: any) {
    super(props, context);
    this.state = {};
  }

  public render() {
    if(this.parkingStore.zoneInfo==null) return (
      <div>Loading...</div>
    );
    else if(!this.parkingStore.zoneInfo.reservedNow)    return (
      <div>
        <MainHeader />
        <FixedPriceSelector />
        <>
          { this.parkingStore.zoneInfo.zoneId == 'TEST' || this.parkingStore.zoneInfo.zoneId == '12' ?
            <p className={style.Zone12} ><b>Warning:  Zone { this.parkingStore.zoneInfo.zoneId} only applies for spaces #18-39.  To pay for all other spaces, please see sign at entrance for Vista Parking.</b></p>
            :
            null
          }
        </>
        <ParkingDetails/>
        <Elements>
          <PaymentForm />
        </Elements>
      </div>
    );
    else {
      return (
        <div>
          <MainHeader />
          <ReservedMessage/>
        </div>
      );

    }
  }

  public componentDidMount() {
    if (this.props.match.params.id != null) {
      this.analyticsService.event(GA_EVENTS.QR_CODE_READ);
      this.apiService.loadZoneInfo(this.props.match.params.id);
    }
  }
}
