import { IAnalyticsService, IAnalyticEvent } from './IAnalyticsService';
import { injectable, inject } from 'inversify';
import * as ReactGA from 'react-ga';
import { action } from 'mobx';

// Constants
import { TYPES } from '../constants';
 
@injectable()
export class GAService implements IAnalyticsService {

    private eventMap : { [key: string]: number };

    public constructor(
        @inject(TYPES.AnalyticService_CONFIG_GA_ID) gATrackingId:string
    ){
        ReactGA.initialize(gATrackingId,{debug: true});
        ReactGA.plugin.require('ecommerce');
        this.eventMap = {};
    }

    public pageView(pageName: string) {
        ReactGA.pageview(pageName);
    }   

    public modalView(modalName: string) {
        ReactGA.modalview(modalName);
    } 
    public event(event:IAnalyticEvent) {
        if(event.captureMultiple || !(event.name in this.eventMap) ) {
            ReactGA.event({category: event.cat, action: event.name});
        }
        if(!(event.name in this.eventMap)) {
            this.eventMap[event.name] = 1;
        }
    }

   

    public successfulParkingTx(amt:string, txId:string,parkingSpotId="unknown") {
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            'id': txId,                     // Transaction ID. Required.
            'revenue': amt,                 // Unit price.
        });
        ReactGA.plugin.execute('ecommerce', 'addItem', {
            'id': txId,                     // Transaction ID. Required.
            'name': 'Parking',
            'sku': parkingSpotId,
            'category': 'Parking',
            'price': amt,
            'quanity': 1                 
        });
        
        ReactGA.plugin.execute('ecommerce', 'send',null);
        ReactGA.plugin.execute('ecommerce', 'clear',null);

    }

}

