import * as React from 'react';
import * as style from './style.css';
import { CardElement } from 'react-stripe-elements';

const createOptions = (fontSize: string) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: '-apple-system, Roboto, sans-serif',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

export class CardElementStripe extends React.Component<{}> {
  public render() {
    return (
      <label className={style.formStripe}>
        <CardElement {...createOptions('16px')} />
      </label>
    );
  }
}
