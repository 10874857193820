import * as React from 'react';
import * as style from './style.css';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import { TYPES } from '../../constants';
import { lazyInject } from '../../utils/inversify.config';
import { IAnalyticsService } from '../../services/IAnalyticsService';
import { GA_EVENTS, MODAL_VIEWS } from '../../constants/gaEvents';

export interface ILearnMoreModalState {
  modalOpen: boolean;
}

export class LearnMoreModal extends React.Component<{}, ILearnMoreModalState> {
  @lazyInject(TYPES.AnalyticService)
  private analyticsService: IAnalyticsService;

  constructor(props: {}, context: any) {
    super(props, context);
    this.state = { modalOpen: false };
  }

  public render() {
    return (
      <Modal
        open={this.state.modalOpen}
        trigger={
          <Button basic inverted size="mini" onClick={this.handleOkClick}>
            How it works
          </Button>
        }
      >
        <Modal.Header>How it works</Modal.Header>
        <Modal.Content>
          <p style={{fontSize: "1.1em"}}>
            Park to Give is the inspiration of two USC alumni with the goal of
            making parking better for residents and guests of our Capital City –
            and at the same time, impact our local community for good.
            <br/>
            <br/>What makes us different?  Well, a few things:
          </p>
          <ol className={style.list}>
            <li>All parking spaces are provided in partnership with local businesses who care about our community.
                These businesses use their parking during the day and make their spaces available for public paid
                 parking during evenings and weekends.
            </li>
            <li>
            All parking is paid for using our mobile platform with support for Apple and Google Pay and all major credit cards.
            </li>
            <li>
            And most importantly, all net proceeds go to support the United Way of the Midlands and their work in 
            making a difference in our local community.
            </li>
            <br/>
            <a href="https://www.parktogive.com" target="_blank">
              Learn more about Park to Give.
            </a>
          </ol>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            className={style.OkButton}
            onClick={this.handleOkClick}
          >
            Ok <Icon name="chevron right" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  private handleOkClick = () => {
    if (!this.state.modalOpen) {
      this.analyticsService.modalView(MODAL_VIEWS.LEARN_MORE);
    }
    this.setState({ modalOpen: !this.state.modalOpen });
  };
}
