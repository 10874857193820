import * as React from 'react';
import * as style from './style.css';
import { Message } from 'semantic-ui-react';
import { PaymentResponse } from '../../remoteapi/generated/api';


export interface IPaymentFailureMessageProps {
  displayMessage: boolean;
  errorMessage: string;
}

export class PaymentFailureMessage extends React.Component<
  IPaymentFailureMessageProps
> {
  public render() {
    return (
      <div
        className={style.PaymentSuccessBox}
        style={{
          display: this.props.displayMessage === true ? 'block' : 'none'
        }}
      >
        <Message negative>
          <Message.Header>There was a problem with your payment</Message.Header>
          <p>
          {this.props.errorMessage}
          </p>

          
        </Message>
      </div>
    );
  }
}
