exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._37FX6Ebc9gfXOo9uqu3Xw8{width:100%;margin-left:auto!important;margin-right:auto!important}.fGnLwcKMLjGLw3uOUGPYY{padding-left:20px!important;font-size:1.1em}", ""]);

// exports
exports.locals = {
	"OkButton": "_37FX6Ebc9gfXOo9uqu3Xw8",
	"list": "fGnLwcKMLjGLw3uOUGPYY"
};