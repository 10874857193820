// Services
import { injectable, inject } from 'inversify';
import { IAPIService2 } from './IAPIService2';

import {DefaultApiInterface as RemoteAPI, ClientInfo}  from '../remoteapi/generated/api';
import { ReceiptInfo, Payment,PaymentResponse,ZoneInfo }  from '../remoteapi/generated/api';

// Constants
import { TYPES } from '../constants';

// Stores
import { IParkingStore } from '../stores/IParkingStore';
import { AxiosResponse, AxiosPromise } from 'axios';
import { error } from 'util';

@injectable()
export class APIService2 implements IAPIService2 {
  
 
  
  @inject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  @inject(TYPES.RemoteAPI) private remoteApi: RemoteAPI;

  public loadClientInfo() {
    this.remoteApi.getClientInfo().then(res=> {
      const info:ClientInfo = res.data ;
      this.parkingStore.setClientInfo(info);
    });
  }
  public loadZoneInfo(zoneId:string) {
    this.remoteApi.getZoneInfo(zoneId).then(res=> {
      if(res.status === 200) {
        const zone:ZoneInfo = res.data as ZoneInfo;
        this.parkingStore.setZoneInfo(zone);
        this.parkingStore.setSelectedPrice(zone.currentPrice);
      }
      else if(res.status === 404) {
        this.parkingStore.setZoneInfo(null);
      }
      
    });
  }

  loadZoneInfoNow(zoneId: string): Promise<ZoneInfo>{
    return this.remoteApi.getZoneInfo(zoneId).then(res=> {
      if(res.status === 200) {
        const zone:ZoneInfo = res.data as ZoneInfo;
        return zone;
      }
      else if(res.status === 404) {
        return null;
      }
    });
  }

  public loadNearestZoneInfo(latitude:number , longitude:number) {
    var z : ZoneInfo = null;
    this.remoteApi.searchNearestZone(latitude,longitude).then(res=> {
      console.log(res.status);
      if(res.status === 200) {
        z = res.data as ZoneInfo;
        this.parkingStore.setZoneInfo(z);
        this.parkingStore.setSelectedPrice(z.currentPrice);
        return true;
      }
      else if(res.status === 404) {
       
      }
      
    });
    this.parkingStore.setZoneInfo(z);3
  }

  

  public sendPaymentInfo(
    stripeToken: string,
    zoneId: string,
    tagNum: string,
    chargeAmountDollars: number,
    email: string
  ): Promise<PaymentResponse> {
    // Create payment Info DTO
    const paymentInfo: Payment = {
      stripeToken,
      zoneId,
      tagNum,
      chargeAmountDollars,
      email
    };

    // Post payment information
    return this.remoteApi.submitPayment(paymentInfo).then(res => {
      const paymentResponse: PaymentResponse = res.data as PaymentResponse;
      return paymentResponse;
    });
  }

  public async sendRecieptInfo(
    localId: string,
    email: string
  ): Promise<AxiosResponse> {
    // Create receipt Info DTO
    const receiptInfo: ReceiptInfo = {
      localId,
      email
    };

    // Post receipt information
    return await this.remoteApi.requestReceipt(receiptInfo);
  }
}
