import { asyncComponent } from 'react-async-component';

const PrivacyPolicyAsync = asyncComponent({
  name: 'PrivacyPolicyAsync',
  serverMode: 'resolve',
  resolve: () => {
    return import(/* webpackChunkName: "PrivacyPolicy" */ './index');
  }
});

export default PrivacyPolicyAsync;
