import * as React from 'react';
import * as style from './style.css';
import { Input, Label, SemanticCOLORS, Button } from 'semantic-ui-react';
import { IParkingStore } from '../../stores/IParkingStore';
import { TYPES } from '../../constants';
import { lazyInject } from '../../utils/inversify.config';
import { observer } from 'mobx-react';

export interface IParkingDetailsState {
  inputError: boolean;
  inputColor: SemanticCOLORS;
}

@observer
export class ParkingDetails extends React.Component<{}, IParkingDetailsState> {
  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;

  constructor(props: {}, context: any) {
    super(props, context);
    this.state = {
      inputError: false,
      inputColor: null
    };
  }

  public async componentDidMount() {
    
  }

  public render() {
    return (
      <div className={style.ParkingDetailsContainer}>
        
        
        <Input
          placeholder="SCABC123"
          labelPosition="left"
          type="text"
          className={style.TagInput}
          error={this.state.inputError}
          pattern="[A-Z0-9]*"
          value={this.parkingStore.tagNumber}
         
        >
          <Label
            style={
              this.state.inputColor === 'blue'
                ? { backgroundColor: '#0088FF', color: '#ffffff' }
                : this.state.inputColor === 'red'
                  ? { backgroundColor: '#c0392b', color: '#ffffff' }
                  : {}
            }
          >
          License Plate
          </Label>
          <input
            min="1"
            onChange={this.handleTextInput}
            style={{ textAlign: 'right' }}
          />
        </Input>
      </div>
    );
  }

 
  private handleTextInput = e => {
    const tag:string = e.target.value;
    if (tag!=null && tag.length > 4) {
      this.setState({ inputError: false, inputColor: 'blue' });
      this.parkingStore.setTagNumber(tag);
    }  else {
      this.parkingStore.setTagNumber(tag);
      this.setState({ inputError: true, inputColor: 'red' });
    }
    
  };


}
