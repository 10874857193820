exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2qC13ENSiERxq2SgB3CLDE{padding:10px;max-width:500px;margin:auto;text-align:center}", ""]);

// exports
exports.locals = {
	"PaymentSuccessBox": "_2qC13ENSiERxq2SgB3CLDE"
};