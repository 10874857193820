import * as React from 'react';
import * as style from './style.css';
import { Input, Label, SemanticCOLORS, Button } from 'semantic-ui-react';
import { IParkingStore } from '../../stores/IParkingStore';
import { TYPES } from '../../constants';
import { ZoneInfoDisplay } from '../ZoneInfoDisplay'
import { lazyInject } from '../../utils/inversify.config';
import { IAPIService2 } from '../../services/IAPIService2';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export interface IFixedPriceSelectorState {
 
}

@observer
export class FixedPriceSelector extends React.Component<{}, IFixedPriceSelectorState> {
  @lazyInject(TYPES.ParkingStore) private parkingStore: IParkingStore;
  @lazyInject(TYPES.APIService) private priceService: IAPIService2;

  constructor(props: {}, context: any) {
    super(props, context);
    this.state = {
     
    };
  }

  public render() {
    return (
      <div className={style.PriceSelectorContainer}>
        <p className={style.ZoneInfo}><b>Parking ${this.parkingStore.zoneInfo.currentPrice} </b></p>
        <ZoneInfoDisplay/>
        <p>All parkers must enter license plate below.</p> 
        <p>Unauthorized vehicles will be towed.</p>
      </div>
    );
  }

  private wrongZoneClicked():void {
    this.props
  }

  

 
}
